import React from "react";
import "../index.css";
import First from "./First";
// import hero from "../svgs/hero.png";
import images from "../svgs";

const Header = () => {
  const [hero, logo, feature1] = images;

  return (
    <div id="app" className="h-full" data-v-app="">
      <div className="relative" data-testid="sign-up-page">
        <div className="mt-24 sm:mt-0" data-testid="main-content">
          <div
            className="flex md:h-screen w-full max-w-full justify-center bg-cover bg-bottom bg-no-repeat px-2 px-6"
            data-testid="hero-section"
            style={{ backgroundImage: 'url("/partners/hero-background.jpg")' }}
          >
            <div
              className="flex max-w-screen-xl flex-col md:justify-center gap-16"
              data-testid="hero-content"
            >
              <div className="flex flex-row gap-10" data-testid="hero-main">
                <div
                  className="flex basis-full flex-col justify-center gap-3 lg:basis-1/2"
                  data-testid="hero-text"
                >
                  <h1
                    className="text-trail-display1 text-grey-800"
                    data-testid="hero-title"
                  >
                    Join the travel connectivity{" "}
                    <span
                      className="text-[#4E6EDD]"
                      data-testid="hero-title-accent"
                    >
                      revolution
                    </span>
                  </h1>
                  <span
                    className="font-ibm-plex-sans text-6 font-normal text-gray-800"
                    data-testid="hero-subtitle"
                  >
                    Partner with the world’s leading eSIM platform and keep
                    people connected, wherever they go
                  </span>
                  <div
                    className="flex flex-row items-center gap-4"
                    data-testid="hero-cta-container"
                  >
                    <button
                      className="bg-ultra-marine-blue-600 text-seashell-100 hover:bg-ultra-marine-blue-500 active:border-seashell-900 border-transparent cursor-pointer border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 tracking-wide"
                      data-testid="hero-cta-button"
                    >
                      <span className="text-3.5 leading-4 font-medium">
                        Become a partner
                      </span>
                    </button>
                    <span
                      className="max-w-36 font-ibm-plex-sans text-3 font-normal text-grey-500"
                      data-testid="hero-cta-info"
                    >
                      Set up your account in less than 5 minutes
                    </span>
                  </div>
                </div>
                <div
                  className="max-w-600 ml-auto hidden basis-1/2 justify-end lg:flex"
                  data-testid="hero-image-container"
                >
                  <img
                    src={hero}
                    className="w-full object-contain"
                    alt="hero illustration"
                    data-testid="hero-image"
                  />
                </div>
              </div>
              <div
                className="md:flex flex-col flex-wrap items-center gap-6 self-center lg:w-3/4 !hidden"
                data-testid="hero-partners"
              >
                <div
                  className="font-ibm-plex-sans text-5 font-medium tracking-tight text-gray-800"
                  data-testid="partners-title"
                >
                  Join 500+ partners that trust Airalo
                </div>
                <div
                  className="flex flex-row flex-wrap items-center justify-center gap-x-10 gap-y-6"
                  data-testid="partners-logos"
                >
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/srilankan-airlines.svg"
                      alt="Srilankan Airlines"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/emirates.svg"
                      alt="Emirates"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/kkday.svg"
                      alt="Kkday"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/be-my-quest.svg"
                      alt="Be my quest"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/airobot.png"
                      alt="Airobot"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/mozio.svg"
                      alt="Mozio"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/b4i-travel.svg"
                      alt="B4I travel"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/azerbaijan-airlines.svg"
                      alt="Azerbaijan Airlines"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/evaair.png"
                      alt="Evaair"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/wakanow.svg"
                      alt="Wakanow"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/highpass.svg"
                      alt="Highpass"
                      data-testid="partner-logo-image"
                    />
                  </div>
                  <div className="bg-no-repeat" data-testid="partner-logo">
                    <img
                      src="/partners/crusalia.svg"
                      alt="Crusalia"
                      data-testid="partner-logo-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
