import React, { useState } from "react";
import "./Fonts.css";
import PhoneInput from "react-phone-number-input";

const PartnershipForm = () => {
  const [step, setStep] = useState(1);
  const [partnershipType, setPartnershipType] = useState("");
  const [email, setEmail] = useState("");

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const handlePartnershipSelect = (type) => {
    setPartnershipType(type);
    handleNextStep();
  };

  return (
    <div className="max-w-500 my-60 px-2 lg:w-1/3">
      <ProgressBar step={step} />
      {step === 1 && (
        <Step1
          onCorporateSelect={() => handlePartnershipSelect("CORPORATE")}
          onResellerSelect={() => handlePartnershipSelect("RESELLER")}
          onAffiliateSelect={() => handlePartnershipSelect("AFFILIATE")}
          onOtherSelect={() => handlePartnershipSelect("OTHER")}
        />
      )}
      {step === 2 && (
        <Step2
          email={email}
          onEmailChange={setEmail}
          onNext={handleNextStep}
          onBack={handleBackStep}
        />
      )}
      {step === 3 && <Step3 onNext={handleNextStep} onBack={handleBackStep} />}
      {step === 4 && <Step4 email={email} onBack={handleBackStep} />}
    </div>
  );
};

const ProgressBar = ({ step }) => {
  return (
    <div className="flex flex-row mb-6" data-testid="corporate-step-progress">
      <div
        className={`border-2 flex-grow first-of-type:rounded-l-sm last-of-type:rounded-r-sm ${
          step >= 1 ? "border-ultra-marine-blue-600" : "border-seashell-900"
        }`}
        data-testid="corporate-step-0"
      ></div>
      <div
        className={`border-2 flex-grow first-of-type:rounded-l-sm last-of-type:rounded-r-sm ${
          step >= 2 ? "border-ultra-marine-blue-600" : "border-seashell-900"
        }`}
        data-testid="corporate-step-1"
      ></div>
      <div
        className={`border-2 flex-grow first-of-type:rounded-l-sm last-of-type:rounded-r-sm ${
          step >= 3 ? "border-ultra-marine-blue-600" : "border-seashell-900"
        }`}
        data-testid="corporate-step-2"
      ></div>
    </div>
  );
};

const Step1 = ({
  onCorporateSelect,
  onResellerSelect,
  onAffiliateSelect,
  onOtherSelect,
}) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="font-medium text-gray-800">Become an Airalo partner</h2>
      <span className="text-sm text-gray-600">
        What would you like to do as a partner?
      </span>
      <div className="flex flex-col gap-4">
        <div
          className="relative flex flex-row-reverse items-center justify-end gap-4 self-stretch rounded-lg border border-seashell-900 px-3 py-2.5"
          data-testid="radio-button-group-CORPORATE"
          onClick={onCorporateSelect}
        >
          I want to buy eSIMs for my company’s employees
          <input
            className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
            type="radio"
          />
          <span className="h-4 min-h-4 w-4 min-w-4 rounded-full border border-grey-50 bg-white"></span>
        </div>
        <div
          className="relative flex flex-row-reverse items-center justify-end gap-4 self-stretch rounded-lg border border-seashell-900 px-3 py-2.5"
          data-testid="radio-button-group-RESELLER"
          onClick={onResellerSelect}
        >
          I want to sell eSIMs to my customers
          <input
            className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
            type="radio"
          />
          <span className="h-4 min-h-4 w-4 min-w-4 rounded-full border border-grey-50 bg-white"></span>
        </div>
        <div
          className="relative flex flex-row-reverse items-center justify-end gap-4 self-stretch rounded-lg border border-seashell-900 px-3 py-2.5"
          data-testid="radio-button-group-AFFILIATE"
          onClick={onAffiliateSelect}
        >
          I want to promote Airalo as an affiliate/influencer
          <input
            className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
            type="radio"
          />
          <span className="h-4 min-h-4 w-4 min-w-4 rounded-full border border-grey-50 bg-white"></span>
        </div>
        <div
          className="relative flex flex-row-reverse items-center justify-end gap-4 self-stretch rounded-lg border border-seashell-900 px-3 py-2.5"
          data-testid="radio-button-group-OTHER"
          onClick={onOtherSelect}
        >
          I want to explore other partnership opportunities
          <input
            className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
            type="radio"
          />
          <span className="h-4 min-h-4 w-4 min-w-4 rounded-full border border-grey-50 bg-white"></span>
        </div>
      </div>
    </div>
  );
};

const Step2 = ({ email, onEmailChange, onNext, onBack }) => {
  const handleEmailChange = (event) => {
    onEmailChange(event.target.value);
  };

  return (
    <div
      data-v-6d31c042=""
      className="max-w-500 my-6 px-2"
      data-testid="router-component"
    >
      <div className="flex flex-col gap-6" data-testid="corporate-stepper">
        <div className="flex flex-col gap-4" data-testid="corporate-step-info">
          <span
            className="text-grey-800 font-ibm-plex-sans text-4 font-normal"
            data-testid="corporate-step-text"
          >
            Step 1/3
          </span>
        </div>
        <div
          data-v-7128e2b0=""
          className="flex flex-col gap-6"
          data-testid="stepper-component"
        >
          <div
            className="flex flex-col gap-4"
            data-testid="corporate-title-container"
          >
            <h3
              className="text-7 font-ibm-plex-sans text-grey-800 font-medium tracking-tight poppins-regular"
              data-testid="corporate-step-title"
            >
              Sign up as an Airalo partner
            </h3>
            <p
              className="roboto-light text-4 font-ibm-plex-sans text-gray-800 font-normal"
              data-testid="corporate-step-description"
            >
              We’ll use this email as the primary contact for your account. You
              can update this once your account is verified.
            </p>
          </div>
          <div
            className="flex flex-col gap-4"
            data-testid="corporate-email-container"
          >
            <div
              className="formGroup relative"
              data-testid="corporate-email-input"
            >
              <div className="flex justify-between mb-2">
                <div data-v-c0372a14="" className="text-sm text-grey-500">
                  <label
                    data-v-c0372a14=""
                    className=" roboto-regular flex-1 ltr:inline-block rtl:block label label--required"
                    htmlFor="email"
                  >
                    Email address*
                  </label>
                </div>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="email"
                  placeholder="Type your email"
                  className="rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="flex text-[24px] absolute ltr:right-[14px] rtl:left-[14px] top-1/2 -translate-y-1/2"></div>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col gap-4"
            data-testid="corporate-button-container"
          >
            <button
              className="text-seashell-100 hover:bg-grey-50 active:border-seashell-900 border-transparent border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 h-12 bg-[#1e4be6]"
              onClick={onNext}
              data-testid="corporate-next-button"
            >
              <span className="text-3.5 leading-4 font-medium">Continue</span>
            </button>
            <button
              className="bg-transparent text-grey-800 hover:bg-seashell-600 active:border-seashell-900 border-seashell-900 cursor-pointer border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 h-12"
              onClick={onBack}
              data-testid="corporate-back-button"
            >
              <span className="text-3.5 leading-4 font-medium">Back</span>
            </button>
          </div>
          <div className="self-center" data-testid="corporate-terms-container">
            <span className=" poppins-regular text-sm text-grey-800 font-ibm-plex-sans font-normal">
              By continuing, you agree to our{" "}
              <a
                className="font-semibold underline"
                href="https://www.airalo.com/more-info/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="corporate-terms-link"
              >
                Terms &amp; Conditions
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step3 = ({ onBack, onNext }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    companyWebsite: "",
    country: "",
    region: "",
    industry: "",
    referral: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext();
    // Handle form submission (e.g., AJAX request)
    alert("Form submitted successfully! 3");
  };

  return (
    <div className="max-w-500 my-6 px-2" data-testid="router-component">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <span
            className="text-grey-800 font-ibm-plex-sans text-4 font-normal"
            data-testid="corporate-step-text"
          >
            Step 2/3
          </span>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <h3
              className="poppins-regular text-7 font-ibm-plex-sans text-grey-800 font-medium tracking-tight"
              data-testid="corporate-step-title"
            >
              Tell us about your company
            </h3>
            <p
              className="roboto-light text-4 font-ibm-plex-sans text-gray-800 font-normal"
              data-testid="corporate-step-description"
            >
              This information helps us tailor your partnership experience to
              your company’s needs.
            </p>
          </div>
          <form onSubmit={handleSubmit} id="step3Form">
            <div className="flex flex-col gap-4">
              <div className="formGroup relative  ">
                <label
                  className="poppins-light text-sm text-grey-500 "
                  htmlFor="companyName"
                >
                  Company name*
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Type your company name"
                  className="poppins-light rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="formGroup relative">
                <label
                  className="poppins-light text-sm text-grey-500"
                  htmlFor="companyWebsite"
                >
                  Company website (Optional)
                </label>
                <input
                  type="text"
                  id="companyWebsite"
                  name="companyWebsite"
                  placeholder="Type your company's website URL"
                  className="poppins-light rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  value={formData.companyWebsite}
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup relative">
                <label
                  className="poppins-light text-sm text-grey-500"
                  htmlFor="country"
                >
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  className="poppins-light rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select your country</option>
                  <option value="india">India</option>
                  <option value="usa">USA</option>
                  <option value="uk">UK</option>
                  <option value="uae">UAE</option>
                </select>
              </div>
              <div className="formGroup relative">
                <label
                  className="poppins-light text-sm text-grey-500"
                  htmlFor="region"
                >
                  Region
                </label>
                <select
                  id="region"
                  name="region"
                  className="poppins-light rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  value={formData.region}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select your region</option>
                  <option value="north">North</option>
                </select>
              </div>
              <div className="formGroup relative">
                <label
                  className="poppins-light text-sm text-grey-500"
                  htmlFor="industry"
                >
                  Industry
                </label>
                <select
                  id="industry"
                  name="industry"
                  className="poppins-light rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  value={formData.industry}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select your industry</option>
                  <option value="it">IT</option>
                </select>
              </div>
              <div className="formGroup relative">
                <label
                  className="poppins-light text-sm text-grey-500"
                  htmlFor="referral"
                >
                  How did you find out about us? (Optional)
                </label>
                <select
                  id="referral"
                  name="referral"
                  className="poppins-light rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  value={formData.referral}
                  onChange={handleChange}
                >
                  <option value="">Select an option</option>
                  <option value="linkedin">Linkedin</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-4 py-5">
              <button
                type="submit"
                className="text-seashell-100 hover:bg-grey-50 active:border-seashell-900 border-transparent border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 h-12 bg-[#1e4be6]"
              >
                <span className="text-3.5 leading-4 font-medium">Continue</span>
              </button>
              <button
                type="button"
                onClick={onBack}
                className="bg-seashell-100 text-grey-800 hover:bg-seashell-600 active:border-seashell-900 border-seashell-900 cursor-pointer border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 h-12"
              >
                <span className="text-3.5 leading-4 font-medium">Back</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const Step4 = ({ onBack, onNext }) => {
  const [fullName, setFullName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phone, setPhone] = useState("");

  const handlePrevStep = (e) => {
    e.preventDefault(); // Prevent default form submission
    onBack(); // Call the onBack function to go back to the previous step
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here if needed
    alert("Form submitted!");
    // Reset state or perform any other actions upon submission
  };

  return (
    <div className="max-w-500 my-60 px-2" data-testid="router-component">
      <div className="flex flex-col gap-6" data-testid="corporate-stepper">
        <div className="flex flex-col gap-4" data-testid="corporate-step-info">
          <span
            className="text-grey-800 font-ibm-plex-sans text-4 font-normal"
            data-testid="corporate-step-text"
          >
            Step 3/3
          </span>
          <div className="flex flex-row" data-testid="corporate-step-progress">
            <div
              className="border-2 border-seashell-900 flex-grow first-of-type:rounded-l-sm last-of-type:rounded-r-sm border-ultra-marine-blue-600"
              data-testid="corporate-step-1"
            ></div>
            <div
              className="border-2 border-seashell-900 flex-grow first-of-type:rounded-l-sm last-of-type:rounded-r-sm border-ultra-marine-blue-600"
              data-testid="corporate-step-2"
            ></div>
            <div
              className="border-2 border-seashell-900 flex-grow first-of-type:rounded-l-sm last-of-type:rounded-r-sm border-ultra-marine-blue-600"
              data-testid="corporate-step-3"
            ></div>
          </div>
        </div>
        <div className="flex flex-col gap-6" data-testid="stepper-component">
          <div
            className="flex flex-col gap-4"
            data-testid="corporate-title-container"
          >
            <h3
              className="poppins-regular text-7 font-ibm-plex-sans text-grey-800 font-medium tracking-tight"
              data-testid="corporate-step-title"
            >
              Tell us about yourself
            </h3>
            <p
              className="roboto-light text-4 font-ibm-plex-sans text-gray-800 font-normal"
              data-testid="corporate-step-description"
            >
              We’ll contact you once your account is verified, or if we have any
              questions during the verification process.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div
              className="flex flex-col gap-4"
              data-testid="corporate-form-container"
            >
              <div
                className="fromGroup relative"
                data-testid="corporate-full-name"
              >
                <div className="flex justify-between mb-2">
                  <div className="text-sm text-grey-500">
                    <label
                      className="poppins-regular flex-1 ltr:inline-block rtl:block label label--required"
                      htmlFor="fullName"
                    >
                      Full name*
                    </label>
                  </div>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="fullName"
                    placeholder="Type your full name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  />
                </div>
              </div>
              <div
                className="fromGroup relative"
                data-testid="corporate-job-title"
              >
                <div className="flex justify-between mb-2">
                  <div className="text-sm text-grey-500">
                    <label
                      className="poppins-regular flex-1 ltr:inline-block rtl:block label label--required"
                      htmlFor="jobTitle"
                    >
                      Job title*
                    </label>
                  </div>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="jobTitle"
                    placeholder="Type your job title"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    className="rounded-lg !bg-transparent !border-seashell-900 input-control w-full block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  />
                </div>
              </div>
              <div
                className="fromGroup relative"
                data-testid="corporate-phone-number"
              >
                <div className="flex justify-between mb-2">
                  <div className="text-sm text-grey-500">
                    <label
                      className="poppins-regular flex-1 ltr:inline-block rtl:block label"
                      htmlFor="phoneNumber"
                    >
                      Phone number
                      <span className="ml-1 lowercase">(Optional)</span>
                    </label>
                  </div>
                </div>
                <div className="relative max-w-[50px] ">
                  <PhoneInput
                    id="phone"
                    international
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                    className="flex flex-row customClass rounded-lg !bg-transparent !border-seashell-900 input-control min-w-max block focus:outline-none h-12 rounded-lg bg-seashell-100 border-seashell-900 placeholder:text-grey-100"
                  />
                </div>
              </div>
            </div>
            <div
              className="flex flex-col py-5"
              data-testid="corporate-button-container"
            >
              <button
                className=" my-8 text-seashell-100 bg-[#1e4be6] hover:bg-grey-50 active:border-seashell-900 border-transparent border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 h-12"
                disabled={!fullName || !jobTitle}
                onClick={handleSubmit}
                data-testid="corporate-next-button"
              >
                <span className="text-3.5 leading-4 font-medium">
                  Send request
                </span>
              </button>
              <button
                className="bg-transparent text-grey-800 hover:bg-seashell-600 active:border-seashell-900 border-seashell-900 cursor-pointer border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 h-12"
                onClick={handlePrevStep}
                data-testid="corporate-back-button"
              >
                <span className="text-3.5 leading-4 font-medium">Back</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PartnershipForm;
