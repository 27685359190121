import React from "react";
import images from "../svgs";

const Fifth = () => {
  const [cardIllustration] = images;
  return (
    <div>
      <div
        className="relative flex flex-col bg-trail-background-secondary bg-top bg-no-repeat px-2 pb-0 pt-24 px-6"
        data-testid="ready-action-section"
      >
        <div
          className="flex w-full max-w-screen-xl flex-col gap-20 self-center"
          data-testid="value-proposition-container"
        >
          <div
            className="flex w-full flex-row items-center rounded-2xl border-solid border-seashell-500 bg-seashell-100 bg-cover px-10 py-6 shadow-trail max-w-[1140px] self-center z-10"
            data-testid="value-proposition-card"
          >
            <div
              className="flex basis-full flex-col items-start gap-6 md:basis-2/3"
              data-testid="value-proposition-content"
            >
              <div className="flex flex-col gap-3">
                <div
                  className="text-trail-display2 text-grey-800"
                  data-testid="value-proposition-title"
                >
                  Ready to take action?
                </div>
                <div
                  className="text-grey-800"
                  data-testid="value-proposition-subtitle"
                >
                  Be part of the connectivity revolution
                </div>
              </div>
              <button
                className="bg-ultra-marine-blue-600 text-seashell-100 hover:bg-ultra-marine-blue-500 active:border-seashell-900 border-transparent cursor-pointer border grid grid-flow-col justify-center items-center gap-2 rounded-lg py-2 px-4 h-11 tracking-wide"
                data-testid="value-proposition-cta"
              >
                <span className="text-3.5 leading-4 font-medium">
                  Become a partner
                </span>
              </button>
            </div>
            <div
              className="hidden md:flex md:basis-1/3"
              data-testid="value-proposition-image-container"
            >
              <img
                src={cardIllustration}
                className="w-auto"
                style={{ width: "300px" }}
                alt="illustration"
                data-testid="value-proposition-image"
              />
            </div>
          </div>
        </div>
        {/* <img
          className="w-full absolute bottom-0 z-0"
          src="/partners/subtract-top.png"
          alt="subtract"
          data-testid="value-proposition-background-image"
        /> */}
      </div>
    </div>
  );
};

export default Fifth;
