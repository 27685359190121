import React, { useState } from "react";
import images from "../svgs";

function Third() {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("corporates");
  const [partner1] = images;

  // Function to handle tab click
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="w-full max-w-[1140px]">
      <div className="flex w-full flex-col">
        <div className="flex flex-wrap md:flex-nowrap rounded-lg border-[1px] border-seashell-900 bg-white w-4/6 self-center">
          <button
            className={`basis-full rounded-lg py-2 text-4 font-medium text-grey-500 m-1 font-weight-bold bg-trail-surface-primary-highlight text-white tab-button ${
              activeTab === "corporates" ? "active" : ""
            }`}
            onClick={() => handleTabClick("corporates")}
            id="tab-corporates"
            data-target="corporates"
          >
            Corporates
          </button>
          <button
            className={`basis-full rounded-lg py-2 text-4 font-medium text-grey-500 m-1 font-weight-bold bg-trail-surface-primary-highlight text-white tab-button ${
              activeTab === "resellers" ? "active" : ""
            }`}
            onClick={() => handleTabClick("resellers")}
            id="tab-resellers"
            data-target="resellers"
          >
            Resellers
          </button>
          <button
            className={`basis-full rounded-lg py-2 text-4 font-medium text-grey-500 m-1 font-weight-bold bg-trail-surface-primary-highlight text-white tab-button ${
              activeTab === "api-partners" ? "active" : ""
            }`}
            onClick={() => handleTabClick("api-partners")}
            id="tab-api-partners"
            data-target="api-partners"
          >
            API Partners
          </button>
          <button
            className={`basis-full rounded-lg py-2 text-4 font-medium text-grey-500 m-1 font-weight-bold bg-trail-surface-primary-highlight text-white tab-button ${
              activeTab === "brand-partners" ? "active" : ""
            }`}
            onClick={() => handleTabClick("brand-partners")}
            id="tab-brand-partners"
            data-target="brand-partners"
          >
            Brand Partners
          </button>
          <button
            className={`basis-full rounded-lg py-2 text-4 font-medium text-grey-500 m-1 font-weight-bold bg-trail-surface-primary-highlight text-white tab-button ${
              activeTab === "affiliates" ? "active" : ""
            }`}
            onClick={() => handleTabClick("affiliates")}
            id="tab-affiliates"
            data-target="affiliates"
          >
            Affiliates
          </button>
          {/* <!-- Add other tab buttons as per your requirement --> */}
        </div>
        <div id="programs-section">
          <div id="program-tabs">
            <div
              id="corporates"
              className={`program-tab ${
                activeTab === "corporates" ? "" : "hidden"
              }`}
            >
              <div id="propgrams-tabs" class="mt-2  undefined">
                <span
                  id="headlessui-tabs-panel-6"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-1"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
                <div
                  id="headlessui-tabs-panel-7"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-2"
                  tabindex="0"
                  data-headlessui-state="selected"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                >
                  <div
                    class="my-24 flex flex-row gap-14 px-20"
                    data-testid="program-tab-content"
                  >
                    <div
                      class="flex basis-full flex-col items-start justify-center gap-4 md:basis-1/2"
                      data-testid="program-tab-details"
                    >
                      <h3
                        class="text-trail-title1 text-trail-text-display-normal"
                        data-testid="program-tab-title"
                      >
                        Airalo for corporates
                      </h3>
                      <p
                        class="text-wrap text-trail-body2 text-trail-text-body-normal"
                        data-testid="program-tab-description"
                      >
                        Supply your business with instant, easy-to-use travel
                        connectivity — keeping employees connected across the
                        globe.
                      </p>
                      <ul
                        class="text-wrap text-trail-body2 text-trail-text-body-normal list-disc pl-5"
                        data-testid="program-tab-points"
                      >
                        <li class="my-1">
                          Simplify and save on travel connectivity
                        </li>
                        <li class="my-1">
                          Get exclusive discounts on every purchase
                        </li>
                        <li class="my-1">
                          Manage and monitor eSIM usage for your teams
                        </li>
                      </ul>
                      <button
                        class="bg-ultra-marine-blue-600
      text-seashell-100
      hover:bg-ultra-marine-blue-500
      active:border-seashell-900
      border-transparent
      cursor-pointer
      border
      grid
      grid-flow-col
      justify-center 
      items-center
      gap-2
      rounded-lg
      py-2
      px-4
      h-11 tracking-wide"
                        data-testid="program-tab-cta"
                      >
                        <span class="text-3.5 leading-4 font-medium">
                          Become a partner
                        </span>
                      </button>
                    </div>
                    <div
                      class="max-w-600 ml-auto hidden basis-1/2 justify-end md:flex"
                      data-testid="program-tab-image-container"
                    >
                      <img
                        src={partner1}
                        class="w-full max-h-80 object-contain"
                        alt="Airalo for resellers"
                        data-testid="program-tab-image"
                      />
                    </div>
                  </div>
                </div>
                <span
                  id="headlessui-tabs-panel-8"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-3"
                ></span>
                <span
                  id="headlessui-tabs-panel-9"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-4"
                ></span>
                <span
                  id="headlessui-tabs-panel-10"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-5"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
              </div>
            </div>
            <div
              id="resellers"
              className={`program-tab ${
                activeTab === "resellers" ? "" : "hidden"
              }`}
            >
              {/* Resellers tab content */}
              <div id="propgrams-tabs" class="mt-2  undefined">
                <span
                  id="headlessui-tabs-panel-6"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-1"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
                <div
                  id="headlessui-tabs-panel-7"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-2"
                  tabindex="0"
                  data-headlessui-state="selected"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                >
                  <div
                    class="my-24 flex flex-row gap-14 px-20"
                    data-testid="program-tab-content"
                  >
                    <div
                      class="flex basis-full flex-col items-start justify-center gap-4 md:basis-1/2"
                      data-testid="program-tab-details"
                    >
                      <h3
                        class="text-trail-title1 text-trail-text-display-normal"
                        data-testid="program-tab-title"
                      >
                        Airalo for resellers
                      </h3>
                      <p
                        class="text-wrap text-trail-body2 text-trail-text-body-normal"
                        data-testid="program-tab-description"
                      >
                        Sell AirAlo's industry-leading eSIMs - supplying
                        customers with instant, affordable travel connectivity.
                      </p>
                      <ul
                        class="text-wrap text-trail-body2 text-trail-text-body-normal list-disc pl-5"
                        data-testid="program-tab-points"
                      >
                        <li class="my-1">
                          Sell eSIM packages for 200+ destinations, around the
                          world
                        </li>
                        <li class="my-1">
                          Seamlessly integrate with third-party e-commerce
                          solutions
                        </li>
                        <li class="my-1">
                          Earn generous commissions on every eSIM purchase
                        </li>
                      </ul>
                      <button
                        class="bg-ultra-marine-blue-600
      text-seashell-100
      hover:bg-ultra-marine-blue-500
      active:border-seashell-900
      border-transparent
      cursor-pointer
      border
      grid
      grid-flow-col
      justify-center 
      items-center
      gap-2
      rounded-lg
      py-2
      px-4
      h-11 tracking-wide"
                        data-testid="program-tab-cta"
                      >
                        <span class="text-3.5 leading-4 font-medium">
                          Become a partner
                        </span>
                      </button>
                    </div>
                    <div
                      class="max-w-600 ml-auto hidden basis-1/2 justify-end md:flex"
                      data-testid="program-tab-image-container"
                    >
                      <img
                        src={partner1}
                        class="w-full max-h-80 object-contain"
                        alt="Airalo for resellers"
                        data-testid="program-tab-image"
                      />
                    </div>
                  </div>
                </div>
                <span
                  id="headlessui-tabs-panel-8"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-3"
                ></span>
                <span
                  id="headlessui-tabs-panel-9"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-4"
                ></span>
                <span
                  id="headlessui-tabs-panel-10"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-5"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
              </div>
            </div>
            <div
              id="api-partners"
              className={`program-tab ${
                activeTab === "api-partners" ? "" : "hidden"
              }`}
            >
              <div id="propgrams-tabs" class="mt-2  undefined">
                <span
                  id="headlessui-tabs-panel-6"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-1"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
                <div
                  id="headlessui-tabs-panel-7"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-2"
                  tabindex="0"
                  data-headlessui-state="selected"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                >
                  <div
                    class="my-24 flex flex-row gap-14 px-20"
                    data-testid="program-tab-content"
                  >
                    <div
                      class="flex basis-full flex-col items-start justify-center gap-4 md:basis-1/2"
                      data-testid="program-tab-details"
                    >
                      <h3
                        class="text-trail-title1 text-trail-text-display-normal"
                        data-testid="program-tab-title"
                      >
                        Airalo for API partners
                      </h3>
                      <p
                        class="text-wrap text-trail-body2 text-trail-text-body-normal"
                        data-testid="program-tab-description"
                      >
                        Integrate Airalo eSIM plans into your website or app —
                        giving customers access to instant, affordable travel
                        connectivity.
                      </p>
                      <ul
                        class="text-wrap text-trail-body2 text-trail-text-body-normal list-disc pl-5"
                        data-testid="program-tab-points"
                      >
                        <li class="my-1">
                          Seamlessly integrate Airalo's inventory into your
                          product
                        </li>
                        <li class="my-1">
                          Sell eSIM packages for 200+ destinations, around the
                          world
                        </li>
                        <li class="my-1">
                          Earn generous commissions on every eSIM purchase
                        </li>
                      </ul>
                      <button
                        class="bg-ultra-marine-blue-600
      text-seashell-100
      hover:bg-ultra-marine-blue-500
      active:border-seashell-900
      border-transparent
      cursor-pointer
      border
      grid
      grid-flow-col
      justify-center 
      items-center
      gap-2
      rounded-lg
      py-2
      px-4
      h-11 tracking-wide"
                        data-testid="program-tab-cta"
                      >
                        <span class="text-3.5 leading-4 font-medium">
                          Become a partner
                        </span>
                      </button>
                    </div>
                    <div
                      class="max-w-600 ml-auto hidden basis-1/2 justify-end md:flex"
                      data-testid="program-tab-image-container"
                    >
                      <img
                        src="/partners/partner-2.svg"
                        class="w-full max-h-80 object-contain"
                        alt="Airalo for resellers"
                        data-testid="program-tab-image"
                      />
                    </div>
                  </div>
                </div>
                <span
                  id="headlessui-tabs-panel-8"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-3"
                ></span>
                <span
                  id="headlessui-tabs-panel-9"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-4"
                ></span>
                <span
                  id="headlessui-tabs-panel-10"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-5"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
              </div>
            </div>
            <div
              id="brand-partners"
              className={`program-tab ${
                activeTab === "brand-partners" ? "" : "hidden"
              }`}
            >
              {/* Brand Partners tab content */}
              <div id="propgrams-tabs" class="mt-2  undefined">
                <span
                  id="headlessui-tabs-panel-6"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-1"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
                <div
                  id="headlessui-tabs-panel-7"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-2"
                  tabindex="0"
                  data-headlessui-state="selected"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                >
                  <div
                    class="my-24 flex flex-row gap-14 px-20"
                    data-testid="program-tab-content"
                  >
                    <div
                      class="flex basis-full flex-col items-start justify-center gap-4 md:basis-1/2"
                      data-testid="program-tab-details"
                    >
                      <h3
                        class="text-trail-title1 text-trail-text-display-normal"
                        data-testid="program-tab-title"
                      >
                        Airalo for brand partners
                      </h3>
                      <p
                        class="text-wrap text-trail-body2 text-trail-text-body-normal"
                        data-testid="program-tab-description"
                      >
                        Introduce innovative eSIM technology to your brand —
                        empowering your users through travel connectivity.
                      </p>
                      <ul
                        class="text-wrap text-trail-body2 text-trail-text-body-normal list-disc pl-5"
                        data-testid="program-tab-points"
                      >
                        <li class="my-1">
                          Explore co-branded and white-label solutions
                        </li>
                        <li class="my-1">
                          Offer eSIMs for 200+ countries and regions
                        </li>
                        <li class="my-1">
                          Earn generous commissions for every eSIM purchase
                        </li>
                      </ul>
                      <button
                        class="bg-ultra-marine-blue-600
      text-seashell-100
      hover:bg-ultra-marine-blue-500
      active:border-seashell-900
      border-transparent
      cursor-pointer
      border
      grid
      grid-flow-col
      justify-center 
      items-center
      gap-2
      rounded-lg
      py-2
      px-4
      h-11 tracking-wide"
                        data-testid="program-tab-cta"
                      >
                        <span class="text-3.5 leading-4 font-medium">
                          Become a partner
                        </span>
                      </button>
                    </div>
                    <div
                      class="max-w-600 ml-auto hidden basis-1/2 justify-end md:flex"
                      data-testid="program-tab-image-container"
                    >
                      <img
                        src="/partners/partner-2.svg"
                        class="w-full max-h-80 object-contain"
                        alt="Airalo for resellers"
                        data-testid="program-tab-image"
                      />
                    </div>
                  </div>
                </div>
                <span
                  id="headlessui-tabs-panel-8"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-3"
                ></span>
                <span
                  id="headlessui-tabs-panel-9"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-4"
                ></span>
                <span
                  id="headlessui-tabs-panel-10"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-5"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
              </div>
            </div>
            <div
              id="affiliates"
              className={`program-tab ${
                activeTab === "affiliates" ? "" : "hidden"
              }`}
            >
              {/* Affiliates tab content */}
              <div id="propgrams-tabs" class="mt-2  undefined">
                <span
                  id="headlessui-tabs-panel-6"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-1"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
                <div
                  id="headlessui-tabs-panel-7"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-2"
                  tabindex="0"
                  data-headlessui-state="selected"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                >
                  <div
                    class="my-24 flex flex-row gap-14 px-20"
                    data-testid="program-tab-content"
                  >
                    <div
                      class="flex basis-full flex-col items-start justify-center gap-4 md:basis-1/2"
                      data-testid="program-tab-details"
                    >
                      <h3
                        class="text-trail-title1 text-trail-text-display-normal"
                        data-testid="program-tab-title"
                      >
                        Airalo for affiliates
                      </h3>
                      <p
                        class="text-wrap text-trail-body2 text-trail-text-body-normal"
                        data-testid="program-tab-description"
                      >
                        Get paid to spread the word about game-changing eSIM
                        technology to your audience.
                      </p>
                      <ul
                        class="text-wrap text-trail-body2 text-trail-text-body-normal list-disc pl-5"
                        data-testid="program-tab-points"
                      >
                        <li class="my-1">
                          Offer the world’s leading eSIM solution to your
                          audience
                        </li>
                        <li class="my-1">
                          Earn generous commissions for every referral purchase
                        </li>
                        <li class="my-1">
                          Leverage the Airalo brand with pre-built brand assets
                        </li>
                      </ul>
                      <button
                        class="bg-ultra-marine-blue-600
      text-seashell-100
      hover:bg-ultra-marine-blue-500
      active:border-seashell-900
      border-transparent
      cursor-pointer
      border
      grid
      grid-flow-col
      justify-center 
      items-center
      gap-2
      rounded-lg
      py-2
      px-4
      h-11 tracking-wide"
                        data-testid="program-tab-cta"
                      >
                        <span class="text-3.5 leading-4 font-medium">
                          Become a partner
                        </span>
                      </button>
                    </div>
                    <div
                      class="max-w-600 ml-auto hidden basis-1/2 justify-end md:flex"
                      data-testid="program-tab-image-container"
                    >
                      <img
                        src="/partners/partner-2.svg"
                        class="w-full max-h-80 object-contain"
                        alt="Airalo for resellers"
                        data-testid="program-tab-image"
                      />
                    </div>
                  </div>
                </div>
                <span
                  id="headlessui-tabs-panel-8"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-3"
                ></span>
                <span
                  id="headlessui-tabs-panel-9"
                  role="tabpanel"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                  aria-labelledby="headlessui-tabs-tab-4"
                ></span>
                <span
                  id="headlessui-tabs-panel-10"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-5"
                  tabindex="-1"
                  class="ring-white ring-opacity-60 ring-offset-1 focus:outline-none"
                  style={{
                    position: "fixed",
                    height: "0px",
                    padding: "0px",
                    overflow: "hidden",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    whiteSpace: "nowrap",
                    borderWidth: "0px",
                  }}
                ></span>
              </div>
            </div>
            {/* <!-- Add other tab content as per your requirement --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Third;
