import React from "react";

const Fourth = () => {
  return (
    <div>
      <div
        className="flex flex-col items-start justify-center gap-20 bg-white px-2 py-24 px-6"
        data-testid="steps-section"
      >
        <div
          className="flex flex-col gap-4 self-center text-center"
          data-testid="steps-header"
        >
          <h2
            className="text-trail-display2 text-gray-900"
            data-testid="steps-title"
          >
            How do I partner with Airalo?
          </h2>
          <h2
            className="font-ibm-plex-sans text-4 font-normal text-grey-800"
            data-testid="steps-subtitle"
          >
            Become an Airalo partner in three easy steps
          </h2>
        </div>
        <div
          className="flex w-full max-w-[1140px] flex-col gap-6 self-center md:flex-row md:gap-0.5"
          data-testid="steps-content"
        >
          <div
            className="flex basis-1/3 flex-col items-center justify-start gap-4 text-center"
            data-testid="step-item"
          >
            <div
              className="flex w-full flex-row items-center gap-2"
              data-testid="step-line-container"
            >
              <hr
                className="invisible h-2 w-full border-t-2 border-dashed border-ultra-marine-blue-500 md:invisible"
                data-testid="step-line-left"
              />
              <div
                className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-ultra-marine-blue-600 p-4 font-ibm-plex-sans text-7 font-medium tracking-tight text-white rounded-t-3xl"
                data-testid="step-number"
              >
                1
              </div>
              <hr
                className="invisible h-2 w-full border-t-2 border-dashed border-ultra-marine-blue-500 md:visible"
                data-testid="step-line-right"
              />
            </div>
            <div className="flex flex-col gap-1" data-testid="step-details">
              <h3
                className="text-trail-title1 text-trail-title-normal w-4/5 self-center"
                data-testid="step-title"
              >
                Choose your partnership
              </h3>
              <div
                className="text-trail-body2 text-trail-body-normal"
                data-testid="step-subtitle"
              >
                Select a solution for your needs
              </div>
            </div>
          </div>

          <div
            className="flex basis-1/3 flex-col items-center justify-start gap-4 text-center"
            data-testid="step-item"
          >
            <div
              className="flex w-full flex-row items-center gap-2"
              data-testid="step-line-container"
            >
              <hr
                className="invisible h-2 w-full border-t-2 border-dashed border-ultra-marine-blue-500 md:visible"
                data-testid="step-line-left"
              />
              <div
                className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-ultra-marine-blue-600 p-4 font-ibm-plex-sans text-7 font-medium tracking-tight text-white rounded-br-3xl"
                data-testid="step-number"
              >
                2
              </div>
              <hr
                className="invisible h-2 w-full border-t-2 border-dashed border-ultra-marine-blue-500 md:visible"
                data-testid="step-line-right"
              />
            </div>
            <div className="flex flex-col gap-1" data-testid="step-details">
              <h3
                className="text-trail-title1 text-trail-title-normal w-4/5 self-center"
                data-testid="step-title"
              >
                Complete your application
              </h3>
              <div
                className="text-trail-body2 text-trail-body-normal"
                data-testid="step-subtitle"
              >
                Enter your company details
              </div>
            </div>
          </div>

          <div
            className="flex basis-1/3 flex-col items-center justify-start gap-4 text-center"
            data-testid="step-item"
          >
            <div
              className="flex w-full flex-row items-center gap-2"
              data-testid="step-line-container"
            >
              <hr
                className="invisible h-2 w-full border-t-2 border-dashed border-ultra-marine-blue-500 md:visible"
                data-testid="step-line-left"
              />
              <div
                className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-ultra-marine-blue-600 p-4 font-ibm-plex-sans text-7 font-medium tracking-tight text-white rounded-tl-3xl"
                data-testid="step-number"
              >
                3
              </div>
              <hr
                className="invisible h-2 w-full border-t-2 border-dashed border-ultra-marine-blue-500 md:invisible"
                data-testid="step-line-right"
              />
            </div>
            <div className="flex flex-col gap-1" data-testid="step-details">
              <h3
                className="text-trail-title1 text-trail-title-normal w-4/5 self-center"
                data-testid="step-title"
              >
                Get verified
              </h3>
              <div
                className="text-trail-body2 text-trail-body-normal"
                data-testid="step-subtitle"
              >
                Become an Airalo partner
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fourth;
