import React from "react";

const Sixth = () => {
  return (
    <div>
      <div
        class="flex flex-col bg-cover bg-top bg-no-repeat px-2 pb-12 pt-16 px-6"
        data-testid="footer-section"
      >
        <div class="flex w-full max-w-screen-xl flex-col gap-20 self-center">
          <div
            class="flex w-full flex-row items-center justify-between max-w-[1200px] text-trail-body3 text-gray-700 self-center"
            data-testid="footer-content"
          >
            <span class="brand" data-testid="footer-brand">
              Airalo Partners is part of the Airalo Organizations
            </span>
            <div class="flex flex-row gap-6" data-testid="footer-links">
              <a
                href="https://www.airalo.com/more-info/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                class="uppercase underline"
                data-testid="footer-privacy-policy"
              >
                Privacy policy
              </a>
              <span data-testid="footer-copyright">© 2024 Airalo</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sixth;
