import hero from "./hero.png";
import logo from "./logo.png";
import feature1 from "./feature1.png";
import feature2 from "./feature2.png";
import feature3 from "./feature3.png";
import feature4 from "./feature4.png";
import feature5 from "./feature5.png";
import feature6 from "./feature6.png";
import partner1 from "./partner1.png";
const images = [
  hero,
  logo,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  partner1,
];

export default images;
