import React from "react";

const Second = () => {
  return (
    <div>
      <div
        class="flex flex-col items-center gap-8"
        data-testid="programs-header"
      >
        <div class="flex flex-col items-center gap-4 text-center">
          <h2
            class="text-trail-display2 text-grey-800"
            data-testid="programs-title"
          >
            Explore our partnership solutions
          </h2>
          <span
            class="font-ibm-plex-sans text-4 font-normal text-grey-800"
            data-testid="programs-subtitle"
          >
            Tap into limitless growth potential when you partner with Airalo
          </span>
        </div>
      </div>
    </div>
  );
};

export default Second;
