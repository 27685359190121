import React from "react";
import images from "../svgs";

const First = () => {
  const [
    hero,
    logo,
    feature1,
    feature2,
    feature3,
    feature4,
    feature5,
    feature6,
  ] = images;

  return (
    <div
      class="flex flex-col items-start justify-center gap-20 bg-white px-2 py-24 px-6"
      data-testid="features-section"
    >
      <div
        class="flex flex-col items-center justify-center gap-4 self-stretch"
        data-testid="features-header"
      >
        <h2
          class="text-trail-display2 text-center text-gray-900"
          data-testid="features-title"
        >
          Why partner with Airalo?
        </h2>
        <span
          class="text-center font-ibm-plex-sans text-4 font-normal text-grey-800 max-w-[700px]"
          data-testid="features-subtitle"
        >
          We offer affordable eSIMs for 200+ countries and regions, worldwide.
          From transforming corporate travel to empowering eSIM resellers, we
          keep the world connected.
        </span>
      </div>
      <div
        class="flex w-full max-w-screen-xl flex-col items-center gap-8 self-center"
        data-testid="features-content"
      >
        <div
          class="flex flex-row flex-wrap justify-center gap-x-16 gap-y-8"
          data-testid="features-metrics"
        >
          <div
            class="flex flex-row items-center gap-4"
            data-testid="feature-metric"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="64"
              fill="none"
              data-testid="feature-metric-icon"
            >
              <rect width="64" height="64" x=".5" fill="#F9FAFB" rx="32"></rect>
              <path
                stroke="#1C64F2"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M20.5 20v2m0 22v-8m0 0 3.694-.923a12 12 0 0 1 8.276.908l.144.072a12 12 0 0 0 8.115.948l4.153-.977A64.707 64.707 0 0 1 44.5 29c0-2.355.127-4.681.375-6.97l-4.146.975a12 12 0 0 1-8.115-.948l-.144-.072a12 12 0 0 0-8.276-.908L20.5 22m0 14V22"
              ></path>
              <path
                fill="#2EA991"
                stroke="#F3F4F6"
                stroke-width="2.745"
                d="M44 27.372a5.372 5.372 0 1 0 0-10.744 5.372 5.372 0 0 0 0 10.744Z"
              ></path>
            </svg>
            <div class="flex flex-col">
              <span
                class="font-ibm-plex-sans text-6 font-medium tracking-tighter text-grey-800"
                data-testid="feature-metric-value"
              >
                200+
              </span>
              <span
                class="font-ibm-plex-sans text-4 font-normal text-grey-800"
                data-testid="feature-metric-name"
              >
                Destinations
              </span>
            </div>
          </div>
          <div
            class="flex flex-row items-center gap-4"
            data-testid="feature-metric"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="64"
              fill="none"
              data-testid="feature-metric-icon"
            >
              <rect width="64" height="64" x=".5" fill="#F3F4F6" rx="32"></rect>
              <path
                stroke="#1C64F2"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M36.5 41.503c1.11.324 2.285.497 3.5.497a12.45 12.45 0 0 0 5.495-1.27 5.5 5.5 0 0 0-10.044-3.324m1.049 4.097V41.5c0-1.484-.38-2.879-1.049-4.093m1.049 4.096-.001.143A16.424 16.424 0 0 1 28 44c-3.109 0-6.017-.86-8.499-2.354a8.5 8.5 0 0 1 15.95-4.239M32.5 24.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm11 3a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
              ></path>
              <path
                fill="#2EA991"
                stroke="#F3F4F6"
                stroke-width="3"
                d="M18 47.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
              ></path>
            </svg>
            <div class="flex flex-col">
              <span
                class="font-ibm-plex-sans text-6 font-medium tracking-tighter text-grey-800"
                data-testid="feature-metric-value"
              >
                10 million+
              </span>
              <span
                class="font-ibm-plex-sans text-4 font-normal text-grey-800"
                data-testid="feature-metric-name"
              >
                Travelers that trust us
              </span>
            </div>
          </div>
          <div
            class="flex flex-row items-center gap-4"
            data-testid="feature-metric"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              fill="none"
              data-testid="feature-metric-icon"
            >
              <rect width="64" height="64" fill="#F3F4F6" rx="32"></rect>
              <path
                stroke="#1C64F2"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M43 27.348c1.179.38 2 1.504 2 2.796v5.714c0 1.516-1.13 2.801-2.64 2.924-.452.037-.905.07-1.36.097V43l-4-4c-1.805 0-3.593-.074-5.36-.218a2.82 2.82 0 0 1-1.1-.322M43 27.348a2.84 2.84 0 0 0-.635-.126 64.866 64.866 0 0 0-10.73 0C30.127 27.347 29 28.63 29 30.144v5.714c0 1.117.613 2.108 1.54 2.602M43 27.348V24.85c0-2.162-1.536-4.036-3.68-4.314A64.594 64.594 0 0 0 31 20c-2.82 0-5.597.182-8.32.536-2.144.278-3.68 2.152-3.68 4.314v8.3c0 2.162 1.536 4.036 3.68 4.314.769.1 1.542.186 2.32.258V44l5.54-5.54"
              ></path>
              <path
                fill="#2EA991"
                stroke="#F3F4F6"
                stroke-width="2.745"
                d="M20 26.372a5.372 5.372 0 1 0 0-10.744 5.372 5.372 0 0 0 0 10.744Z"
              ></path>
            </svg>
            <div class="flex flex-col">
              <span
                class="font-ibm-plex-sans text-6 font-medium tracking-tighter text-grey-800"
                data-testid="feature-metric-value"
              >
                24 x 7
              </span>
              <span
                class="font-ibm-plex-sans text-4 font-normal text-grey-800"
                data-testid="feature-metric-name"
              >
                Customer &amp; Partner support
              </span>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-6 self-stretch md:flex-row"
          data-testid="features-list"
        >
          <div
            class="bg-ultra-marine-blue-100 md:w-2/3 md:max-w-[620px] flex w-full flex-col items-start gap-4 self-center rounded-xl p-8 md:self-stretch"
            data-testid="feature-item"
          >
            <span
              class="text-trail-title1 text-trail-text-display-normal"
              data-testid="feature-title"
            >
              All-in-one tool
            </span>
            <div
              class="text-trail-body1 text-trail-text-body-normal"
              data-testid="feature-description"
            >
              Purchase, share, and monitor eSIMs on a single, easy-to-use
              platform.
            </div>
            <img
              class="w-auto max-w-full self-center"
              src={feature1}
              alt="Purchase, share, and monitor eSIMs on a single, easy-to-use platform."
              data-testid="feature-image"
            />
          </div>
          <div
            class="bg-gray-100 md:w-1/3 flex w-full flex-col items-start gap-4 self-center rounded-xl p-8 md:self-stretch"
            data-testid="feature-item"
          >
            <span
              class="text-trail-title1 text-trail-text-display-normal"
              data-testid="feature-title"
            >
              Seamless connectivity, simplified
            </span>
            <div
              class="text-trail-body1 text-trail-text-body-normal"
              data-testid="feature-description"
            >
              Keep your users connected with innovative eSIM solutions — without
              compromise.
            </div>
            <img
              class="w-auto max-w-full self-center"
              src={feature2}
              alt="Keep your users connected with innovative eSIM solutions — without compromise."
              data-testid="feature-image"
            />
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-6 self-stretch md:flex-row"
          data-testid="features-list"
        >
          <div
            class="bg-gray-100 md:w-1/3 flex w-full flex-col items-start gap-4 self-center rounded-xl p-8 md:self-stretch"
            data-testid="feature-item"
          >
            <span
              class="text-trail-title1 text-trail-text-display-normal"
              data-testid="feature-title"
            >
              Global eSIM coverage
            </span>
            <div
              class="text-trail-body1 text-trail-text-body-normal"
              data-testid="feature-description"
            >
              Access 900+ eSIM packages for 200+ countries and regions,
              worldwide.
            </div>
            <img
              class="w-auto max-w-full self-center"
              src={feature3}
              alt="Access 900+ eSIM packages for 200+ countries and regions, worldwide."
              data-testid="feature-image"
            />
          </div>
          <div
            class="bg-ultra-marine-blue-100 md:w-2/3 md:max-w-[620px] flex w-full flex-col items-start gap-4 self-center rounded-xl p-8 md:self-stretch"
            data-testid="feature-item"
          >
            <span
              class="text-trail-title1 text-trail-text-display-normal"
              data-testid="feature-title"
            >
              Tailored connectivity solutions
            </span>
            <div
              class="text-trail-body1 text-trail-text-body-normal"
              data-testid="feature-description"
            >
              Choose the connectivity solution that best fits your business
              needs, whether it's our easy-to-integrate API or all-in-one
              Partner Platform.
            </div>
            <img
              class="w-auto max-w-full self-center"
              src={feature4}
              alt="Choose the connectivity solution that best fits your business needs, whether it's our easy-to-integrate API or all-in-one Partner Platform."
              data-testid="feature-image"
            />
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center gap-6 self-stretch md:flex-row"
          data-testid="features-list"
        >
          <div
            class="bg-ultra-marine-blue-100 md:w-2/3 md:max-w-[620px] flex w-full flex-col items-start gap-4 self-center rounded-xl p-8 md:self-stretch"
            data-testid="feature-item"
          >
            <span
              class="text-trail-title1 text-trail-text-display-normal"
              data-testid="feature-title"
            >
              eSIM analytics
            </span>
            <div
              class="text-trail-body1 text-trail-text-body-normal"
              data-testid="feature-description"
            >
              Leverage insights on eSIM users, transactions, markets, and trends
              to make informed business decisions.
            </div>
            <img
              class="w-auto max-w-full self-center"
              src={feature5}
              alt="Leverage insights on eSIM users, transactions, markets, and trends to make informed business decisions."
              data-testid="feature-image"
            />
          </div>
          <div
            class="bg-gray-100 md:w-1/3 flex w-full flex-col items-start gap-4 self-center rounded-xl p-8 md:self-stretch"
            data-testid="feature-item"
          >
            <span
              class="text-trail-title1 text-trail-text-display-normal"
              data-testid="feature-title"
            >
              24/7 support
            </span>
            <div
              class="text-trail-body1 text-trail-text-body-normal"
              data-testid="feature-description"
            >
              Access our dedicated multi-language support desk, whenever you
              need it.
            </div>
            <img
              class="w-auto max-w-full self-center"
              src={feature6}
              alt="Access our dedicated multi-language support desk, whenever you need it."
              data-testid="feature-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
