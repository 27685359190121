import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import PartnershipForm from "./components/PartnershipForm";
import logo from "./svgs/logo.png";
import First from "./components/First";

import Second from "./components/Second";
import Third from "./components/Third";
import Fourth from "./components/Fourth";
import Fifth from "./components/Fifth";
import Sixth from "./components/Sixth";
const App = () => {
  const redirectToOnboarding = () => {
    // Custom logic to navigate to /onboarding
    window.location.href = "/onboarding";
  };
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={<HomeLayout redirectToOnboarding={redirectToOnboarding} />}
          />
          <Route path="/onboarding" element={<OnboardingLayout />} />
        </Routes>
      </div>
    </Router>
  );
};

const OnboardingLayout = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-secondary-50">
      <div className="flex w-full flex-row items-center justify-between gap-3 bg-seashell-500 px-8 py-6 z-50 fixed top-0">
        <div>
          <img
            src={logo}
            alt="Logo"
            className="w-full cursor-pointer"
            data-testid="logo"
          />
        </div>
        <div className="flex gap-4 text-nowrap" data-testid="cta-container">
          <button
            className="bg-transparent text-grey-800 hover:bg-seashell-600 active:border-seashell-900 border-seashell-900 cursor-pointer rounded-lg py-2 px-4 h-11"
            data-testid="sign-in-button"
          >
            <span className="text-3.5 leading-4 font-medium">Sign in</span>
          </button>
          <button
            className="bg-ultra-marine-blue-600 text-seashell-100 hover:bg-ultra-marine-blue-500 active:border-seashell-900 border-transparent cursor-pointer rounded-lg py-2 px-4 h-11"
            data-testid="sign-up-button"
          >
            <span className="text-3.5 leading-4 font-medium">
              Become a partner
            </span>
          </button>
        </div>
      </div>
      <PartnershipForm />
    </div>
  );
};

const HomeLayout = ({ redirectToOnboarding }) => {
  return (
    <header>
      <div className="flex flex-col items-center justify-center bg-secondary-50">
        <div className="flex w-full flex-row items-center justify-between gap-3 bg-seashell-500 px-8 py-6 z-50 fixed top-0">
          <div>
            <img
              src={logo}
              alt="Logo"
              className="w-full cursor-pointer"
              data-testid="logo"
            />
          </div>
          <div className="flex gap-4 text-nowrap" data-testid="cta-container">
            <button
              className="bg-transparent text-grey-800 hover:bg-seashell-600 active:border-seashell-900 border-seashell-900 cursor-pointer rounded-lg py-2 px-4 h-11"
              data-testid="sign-in-button"
            >
              <span className="text-3.5 leading-4 font-medium">Sign in</span>
            </button>
            <button
              onClick={redirectToOnboarding}
              className="bg-ultra-marine-blue-600 text-seashell-100 hover:bg-ultra-marine-blue-500 active:border-seashell-900 border-transparent cursor-pointer rounded-lg py-2 px-4 h-11"
              data-testid="sign-up-button"
            >
              <span className="text-3.5 leading-4 font-medium">
                Become a partner
              </span>
            </button>
          </div>
        </div>
      </div>
      <Header />
      <First />
      <div className="flex w-full flex-col items-center justify-center gap-8 bg-secondary-50 py-24 px-6">
        <Second />
        <Third />
      </div>
      <Fourth />
      <Fifth />
      <Sixth />
    </header>
  );
};

export default App;
